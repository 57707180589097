import * as React from "react"

const IndexPage = () => {
  // https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
  if (typeof window !== "undefined") {
    // @ts-ignore forceful redirection without real language detection
    window["location"] = "/en-us/"
  }
  return (<></>);
}

export default IndexPage
